import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { ProfileConfigButton } from '../../utils/utils';
import { StyledBox } from '../../common/styledCommonComponents';
import AlertIcon from '../../assets/AlertIcon';
import StyledText from '../../common/components/Text';
import { useLocation, useNavigate } from 'react-router';
import SymptomCard from './components/SymptomCard';
import { SymptomReport } from '../../redux/api/types';
import { useGetSymptomReportsByIdQuery } from '../../redux/api/symptomsApi';
import { useParams } from 'react-router-dom';

const PatientSymptomsSummaryCard = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const location = useLocation();

	const { patientId } = useParams();

	const {
		data: patientReports,
		isSuccess: isSuccessClosedForms,
		isLoading,
	} = useGetSymptomReportsByIdQuery({ patientId, firstPage: 0, pageSize: 50 });

	const [reports, setReports] = useState<SymptomReport[]>([
		{
			reportId: 'a83c37d0-5e15-40fe-8f43-318a04d0c00c',
			createdAt: 1736275301054,
			checked: true,
			symptoms: [
				{
					symptomId: 'b827894e-0466-4578-a00c-7a326d1717cf',
					symptom: 'Diarrea',
					intensityGrade: 4,
					redFlag: true,
					intensityDescription:
						'Deposiciones incontrolables (incontinencia o uso de pañal)',
				},
				{
					symptomId: 'd900dc34-813a-4d27-8459-cfb8bf75f222',
					symptom: 'Estreñimiento',
					intensityGrade: 1,
					redFlag: false,
					intensityDescription: 'Síntomas ocasionales o intermitentes',
				},
			],
		},
		{
			reportId: 'ecda1a43-49ad-4ee6-a884-c9fb6b6999ad',
			createdAt: 1734626992652,
			checked: true,
			symptoms: [
				{
					symptomId: 'b827894e-0466-4578-a00c-7a326d1717cf',
					symptom: 'Diarrea',
					intensityGrade: 4,
					redFlag: true,
					intensityDescription:
						'Deposiciones incontrolables (incontinencia o uso de pañal)',
				},
				{
					symptomId: 'd900dc34-813a-4d27-8459-cfb8bf75f222',
					symptom: 'Estreñimiento',
					intensityGrade: 1,
					redFlag: false,
					intensityDescription: 'Síntomas ocasionales o intermitentes',
				},
			],
		},
		{
			reportId: '1685cf7c-204a-41b6-8379-47c4d3dcc33b',
			createdAt: 1734626246129,
			checked: false,
			symptoms: [
				{
					symptomId: 'd900dc34-813a-4d27-8459-cfb8bf75f222',
					symptom: 'Estreñimiento',
					intensityGrade: 1,
					redFlag: false,
					intensityDescription: 'Síntomas ocasionales o intermitentes',
				},
			],
		},
	]);

	console.log(patientReports);

	return (
		<StyledBox
			css={{
				maxWidth: '878px',
				display: 'flex',
				flexDirection: 'column',
				gap: 16,
				width: '820px',
				padding: '24px',
				borderRadius: '20px',
				background: '#FFF',
				boxShadow: '0px 1px 25px 0px rgba(175, 126, 255, 0.10)',
			}}
		>
			<>
				<StyledBox
					css={{
						display: 'flex',
						paddingBottom: 8,
						justifyContent: 'space-between',
						alignItems: 'flex-end',
						width: '100%',
						borderBottom: `1px solid ${theme.gray50}`,
					}}
				>
					<StyledBox
						css={{
							display: 'flex',
							alignItems: 'center',
							gap: '8px',
						}}
					>
						<AlertIcon width={20} height={20} color={theme.error400} />
						<StyledText
							variant="body2Bold"
							css={{
								color: theme.error400,
								height: '17px',
							}}
						>
							Reportó síntomas
						</StyledText>
					</StyledBox>
					<StyledText
						variant="body2Bold"
						css={{
							color: theme.error400,
							textAlign: 'center',
							cursor: 'pointer',
						}}
						onClick={() => navigate(`${location.pathname}/symptoms`)}
					>
						Ver todo
					</StyledText>
				</StyledBox>
				<StyledBox
					css={{
						display: 'flex',
						alignItems: 'flex-start',
						flex: 1,
						gap: '16px',
						overflowX: 'auto',
					}}
				>
					{patientReports?.reports?.map((report) => (
						<SymptomCard key={report.reportId} {...report} />
					))}
				</StyledBox>
			</>
		</StyledBox>
	);
};

export default PatientSymptomsSummaryCard;
