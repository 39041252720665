import React, { useEffect, useMemo } from 'react';
import HomeScreen from './pages/Home/HomeScreen';
import LoginScreen from './pages/Login/LoginScreen';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import ProfileScreen from './pages/PatientProfile/ProfileScreen';
import Wrapper from './components/Navbar/Wrapper';
import PatientListScreen from './pages/PatientList/PatientListScreen';
import PatientFormsScreen from './pages/PatientReportScreen/PatientReportScreen';
import ProtectedRoute from './pages/routes/ProtectedRoute';
import SettingScreen from './pages/Settings';
import MyTeamListScreen from './pages/MyTeamList/MyTeamListScreen';
import { useAuth0 } from '@auth0/auth0-react';
import { useRoleCheckQuery } from './redux/api/sessionApi';
import PublicRoute from './pages/routes/PublicRoute';
import DoctorsAcceptanceListScreen from './pages/DoctorsAcceptanceList/DoctorsAcceptanceList';
import Register from './pages/Register';
import ValidationForm from './pages/ValidationForm';
import LoadingScreen from './pages/LoadingScreen/LoadingScreen';
import MedicalStudyList from './pages/MedicalStudyList';
import SymptomsReportScreen from './pages/PatientProfile/SymptomsReportScreen';
// import AlertsScreen from './pages/Alerts/AlertsScreen';
import RecentReportsScreen from './pages/RecentReports/RecentReportsScreen';

const App = () => {
	const {
		isAuthenticated,
		getAccessTokenSilently,
		isLoading: AuthLoading,
		loginWithRedirect,
	} = useAuth0();

	const { isSuccess, isError, isLoading, refetch } = useRoleCheckQuery();

	const isVerified = useMemo(() => {
		if (isSuccess && !isError && !isLoading) {
			return true;
		}
	}, [isSuccess, isError, isLoading]);

	useEffect(() => {
		try {
			if (isAuthenticated && !localStorage.getItem('token')) {
				getAccessTokenSilently().then((res) => {
					localStorage.setItem('token', res);
					refetch();
				});
			}
		} catch (e: any) {
			if (
				e.error === 'missing_refresh_token' ||
				e.error === 'invalid_grant'
			) {
				loginWithRedirect();
			}
		}
	}, [isAuthenticated]);

	return (
		<BrowserRouter>
			<Routes>
				<Route path={'/loading'} element={<LoadingScreen />} />
				<Route
					element={
						<ProtectedRoute
							isLoading={isLoading}
							hasAccess={isVerified && isAuthenticated}
						/>
					}
				>
					<Route element={<Wrapper />}>
						<Route path="/home" element={<HomeScreen />} />
						<Route path="/my-patients" element={<PatientListScreen />} />
						<Route path="/mi-equipo" element={<MyTeamListScreen />} />
						<Route
							path="/aprobar-medico"
							element={<DoctorsAcceptanceListScreen />}
						/>

						<Route path="/settings" element={<SettingScreen />} />
						<Route
							path={'/profile/:patientId'}
							element={<ProfileScreen />}
						/>
						<Route
							path={'/profile/:patientId/symptoms'}
							element={<SymptomsReportScreen />}
						/>
						<Route
							path={'/studies/:patientId'}
							element={<MedicalStudyList />}
						/>
						{/* <Route path="/alerts" element={<AlertsScreen />} /> */}
						<Route path="/recent" element={<RecentReportsScreen />} />
						<Route
							path={'/reportes/:patientId'}
							element={<PatientFormsScreen />}
						/>
						<Route
							path="/validate-patient/:patientId"
							element={<ValidationForm />}
						/>
						<Route
							path="/validate/:patientId"
							element={<ValidationForm />}
						/>
						{/* <Route path="/archivos" element={<RepositoryScreen />} /> */}
						<Route path="/auxiliar" element={<div>AUXILIAR</div>} />
					</Route>
				</Route>
				<Route
					element={
						<ProtectedRoute
							isLoading={isLoading || AuthLoading}
							hasAccess={isAuthenticated && !isVerified}
						/>
					}
				>
					<Route path="/registro" element={<Register />} />
				</Route>
				<Route
					element={
						<PublicRoute hasAccess={isVerified && isAuthenticated} />
					}
				>
					<Route path="/login" element={<LoginScreen />} />
					<Route path={'*'} element={<Navigate to="/login" replace />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
};

export default App;
