import React from 'react';
import Card from '../Card';
import {
	StyledBox,
	StyledColumn,
	StyledRow,
} from '../../common/styledCommonComponents';
import { useTheme } from 'styled-components';
import StyledText from '../../common/components/Text';
import { useGetSymptomsReportQuery } from '../../redux/api/symptomsApi';
import ErrorComponent from '../ErrorComponent';
import { StyledCircularProgress } from '../CustomCircularProgress/styles';
import { StyledH6 } from '../../common/components/Text/styles';
import { ReportCard } from './components/ReportCard';
import { useNavigate } from 'react-router';

export const WidgetSymptomsAlerts = () => {
	const theme = useTheme();
	const {
		data,
		isLoading: loadingQuery,
		refetch,
		isError,
	} = useGetSymptomsReportQuery({ firstPage: 0, pageSize: 10 });

	const reports = data?.reports ?? [];

	return (
		<Card
			headerComponent={Header({ alertsCount: reports.length })}
			width={'810px'}
			css={{ maxHeight: '' }}
		>
			<StyledBox
				css={{
					width: '100%',
					height: '100%',
					overflowY: 'auto',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				{loadingQuery ? (
					<StyledBox
						css={{
							height: '100%',
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<StyledCircularProgress />
					</StyledBox>
				) : isError ? (
					<ErrorComponent reload={() => refetch()} />
				) : (
					<StyledColumn
						css={{
							alignItems: 'flex-start',
							gap: '8px',
							width: '100%',
							alignSelf: 'stretch',
							height: '100%',
						}}
					>
						{reports.length > 0 ? (
							<StyledRow
								css={{
									alignItems: 'stretch',
									gap: '16px',
								}}
							>
								{reports.map(({ report, patient }, index) => (
									<ReportCard
										patient={patient}
										report={report}
										key={`${patient.id}-${index}`}
									/>
								))}
							</StyledRow>
						) : (
							<StyledBox
								css={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									height: '164px',
									width: '100%',
								}}
							>
								<StyledH6 css={{ color: theme.gray300 }}>
									Sin Datos
								</StyledH6>
							</StyledBox>
						)}
					</StyledColumn>
				)}
			</StyledBox>
		</Card>
	);
};

const Header = ({ alertsCount }: { alertsCount: number }) => {
	const theme = useTheme();
	const navigate = useNavigate();
	return (
		<StyledBox
			css={{
				display: 'flex',
				padding: '4px 4px 8px 4px',
				justifyContent: 'space-between',
				alignItems: 'flex-en',
				alignSelf: 'stretch',
				borderBottom: `1px solid ${theme.gray50}`,
			}}
		>
			<StyledText
				variant="body2Bold"
				css={{
					color: theme.gray800,
				}}
			>
				{alertsCount} Alertas nuevas
			</StyledText>
			{alertsCount > 0 && (
				<StyledText
					variant="body2Bold"
					css={{
						color: theme.error400,
						textAlign: 'center',
						cursor: 'pointer',
					}}
					onClick={() => navigate(`/recent`)}
				>
					Ver todo
				</StyledText>
			)}
		</StyledBox>
	);
};
