import React from 'react';
import AlertIcon from '../../../assets/outlined/AlertIcon';
import {
	StyledBox,
	StyledColumn,
	StyledRow,
	StyledSpan,
} from '../../../common/styledCommonComponents';
import Card from '../../Card';
import { PatientsSymptomsReports } from '../../../redux/api/types';
import { useTheme } from 'styled-components';
import StyledText from '../../../common/components/Text';
import Button from '../../../common/components/Button';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { useOncoDispatch } from '../../../hooks/redux-hook';
import { setModalOpen } from '../../../redux/slices/utilsSlice';
import { ModalTypeEnum } from '../../../utils/utils';

export const ReportCard = ({
	patient,
	report,
}: PatientsSymptomsReports['reports'][number]) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const { symptoms } = report;
	const dispatch = useOncoDispatch();

	const handleOpenModal = () => {
		dispatch(
			setModalOpen({
				open: true,
				type: ModalTypeEnum.SYMPTOM_REPORT,
				report: report,
				patientId: patient.patientProfileId,
			}),
		);
	};

	const hasRedFlag = symptoms.some((symptom) => symptom.redFlag);
	const SLICE_LENGTH = 3;

	const color100 = hasRedFlag ? theme.error100 : theme.warning100;
	const color300 = hasRedFlag ? theme.error300 : theme.warning300;
	const color400 = hasRedFlag ? theme.error400 : theme.warning400;
	const color700 = hasRedFlag ? theme.error700 : theme.warning700;

	const duration = moment(report.createdAt).fromNow();

	return (
		<Card
			width="auto"
			height="255px"
			headerComponent={<></>}
			css={{
				backgroundColor: color100,
				position: 'relative',
			}}
		>
			<StyledColumn
				css={{
					gap: '8px',
					justifyContent: 'space-between',
					height: '100%',
				}}
			>
				<StyledColumn css={{ gap: '8px' }}>
					<StyledRow css={{ alignItems: 'center', gap: '8px' }}>
						<StyledBox
							css={{
								padding: '4px',
								borderRadius: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								backgroundColor: color400,
							}}
						>
							<AlertIcon width={28} height={28} color="white" />
						</StyledBox>
						<StyledText
							variant="body2Bold"
							css={{ color: color700, cursor: 'pointer' }}
							onClick={() =>
								navigate(`/profile/${patient.patientProfileId}`)
							}
						>
							{patient.name} {patient.surname}
						</StyledText>
						<StyledText
							variant="body3"
							css={{
								color: color700,
								whiteSpace: 'nowrap',
							}}
						>
							{duration}
						</StyledText>
					</StyledRow>
					<StyledColumn css={{ gap: '8px' }}>
						<ul style={{ paddingLeft: '30px' }}>
							{symptoms.slice(0, SLICE_LENGTH).map((symptom) => (
								<li key={symptom.symptomId} style={{ margin: '8px 0' }}>
									<StyledText
										variant="body2Bold"
										css={{
											color: color700,
										}}
									>
										{symptom.symptom}{' '}
										<StyledText variant="body3" as={'span'}>
											(Grado {symptom.intensityGrade})
										</StyledText>
									</StyledText>
								</li>
							))}
						</ul>
						{symptoms.length > SLICE_LENGTH && (
							<StyledText
								variant="body3"
								css={{
									color: color400,
									backgroundColor: color300,
									width: 'fit-content',
									padding: '4px',
									borderRadius: '8px',
								}}
							>
								+{symptoms.length - SLICE_LENGTH} síntomas
							</StyledText>
						)}
					</StyledColumn>
				</StyledColumn>

				<StyledRow css={{ justifyContent: 'flex-end' }}>
					<Button
						onClick={handleOpenModal}
						size="small"
						css={{
							backgroundColor: color400,
							'&:hover': { backgroundColor: color700 },
						}}
					>
						Ver más
					</Button>
				</StyledRow>
			</StyledColumn>
			{!report.checked && (
				<StyledBox
					css={{
						position: 'absolute',
						top: 0,
						right: 0,
						height: 12,
						width: 12,
						borderRadius: '50%',
						backgroundColor: theme.error400,
					}}
				></StyledBox>
			)}
		</Card>
	);
};
