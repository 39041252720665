import { api } from './api';
import {
	PatientsSymptomsReports,
	RecentSymptomsReport,
	Recommendation,
	SymptomDetail,
	SymptomsReportResponse,
} from './types';

export const symptomsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		symptoms: builder.query<SymptomDetail[], void>({
			query: () => ({ url: '/symptom', method: 'GET' }),
		}),
		getRecentSymptomsReports: builder.query<RecentSymptomsReport[], void>({
			query: () => ({
				url: '/symptom/report/patients/recent',
				method: 'GET',
			}),
		}),
		getSymptomsReport: builder.query<PatientsSymptomsReports, any>({
			query: ({ firstPage = 0, pageSize = 10 }) => ({
				url: '/symptom/report/patients',
				method: 'GET',
				params: { firstPage, pageSize },
			}),
		}),
		getSymptomReportsById: builder.query<SymptomsReportResponse, any>({
			query: ({ patientId, firstPage, pageSize }) => ({
				url: `symptom/report/patients/${patientId}`,
				method: 'GET',
				params: { firstPage, pageSize },
			}),
		}),
		getReportRecommendations: builder.query<Recommendation[], string>({
			query: (reportId) => ({
				url: `symptom/report/${reportId}/recommendations`,
				method: 'GET',
			}),
		}),
		checkReport: builder.query<void, string>({
			query: (reportId) => ({
				url: `symptom/report/${reportId}/check`,
				method: 'POST',
			}),
		}),
	}),
});

export const {
	useSymptomsQuery,
	useGetRecentSymptomsReportsQuery,
	useGetSymptomReportsByIdQuery,
	useGetSymptomsReportQuery,
	useGetReportRecommendationsQuery,
	useCheckReportQuery,
} = symptomsApi;

export const {
	endpoints: { symptoms },
} = symptomsApi;
