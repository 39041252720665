import React from 'react';
import { IconProps } from '../IconInterface';

const AlertIcon = ({
	color = '#000000',
	width = 24,
	height = 24,
}: IconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			fill="none"
		>
			<path
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M12.472 5.537 4.905 18.295a1.819 1.819 0 0 0 .642 2.457c.268.16.574.246.886.25h15.133a1.795 1.795 0 0 0 1.532-.91 1.82 1.82 0 0 0-.005-1.797L15.527 5.537a1.795 1.795 0 0 0-.651-.637 1.773 1.773 0 0 0-2.404.637ZM14 10.5V14M14 17.5h.012"
			/>
		</svg>
	);
};
export default AlertIcon;
