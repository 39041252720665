import React, { useMemo } from 'react';
import { StyledColumn, StyledRow } from '../../common/styledCommonComponents';
import WidgetHome from '../../components/WidgetHome';
import WidgetPastelStats from '../../components/WidgetPastelStats';
import PatientsList from '../../components/PatientsList';
import WaitingList from '../../components/WaitingList';
import { FrequencySymptomsChart } from '../../components/FrequencySymptomsChart';
import { useAuth0 } from '@auth0/auth0-react';
import { WidgetSymptomsAlerts } from '../../components/WidgetSymptomsAlerts';

function HomeScreen() {
	const { user } = useAuth0();

	const isAuxiliar = useMemo(() => {
		if (user === undefined) return true;
		return user[
			(process.env.REACT_APP_AUTH0_AUDIENCE as string) + '/roles'
		].some((str: string) => str === 'Auxiliar');
	}, [user]);

	return (
		<StyledRow
			css={{
				padding: '30px 60px',
				height: 'inherit',
				width: 'inherit',
				justifyContent: 'center',
			}}
		>
			<StyledRow
				css={{
					height: '840px',
				}}
			>
				<StyledColumn
					css={{
						maxWidth: '810px',
						gap: 20,
					}}
				>
					<WidgetSymptomsAlerts />
					<WidgetHome />
					<WidgetPastelStats />
					<FrequencySymptomsChart />
				</StyledColumn>
				<StyledColumn
					css={{
						marginLeft: 25,
						display: 'flex',
						height: '100%',
						gap: 20,
					}}
				>
					{!isAuxiliar && <WaitingList />}
					<PatientsList />
				</StyledColumn>
			</StyledRow>
		</StyledRow>
	);
}

export default HomeScreen;
