import React from 'react';
import {
	StyledBox,
	StyledColumn,
	StyledRow,
	StyledScreen,
} from '../../common/styledCommonComponents';
import { useTheme } from 'styled-components';
import {
	useGetRecentSymptomsReportsQuery,
	useSymptomsQuery,
} from '../../redux/api/symptomsApi';
import moment from 'moment';
import StyledText from '../../common/components/Text';
import { StyledSymptomTable } from '../PatientProfile/components/SymptomsDashboard/styles';
import Moment from 'react-moment';
import { StyledCircularProgress } from '../../components/CustomCircularProgress/styles';
import { NotFoundMessage } from '../PatientProfile/components/NotFoundMessage';
import { StyledH6 } from '../../common/components/Text/styles';

const RecentReportsScreen = () => {
	const theme = useTheme();
	const { data: symptomsList } = useSymptomsQuery();
	const { data, isSuccess, isLoading, isError } =
		useGetRecentSymptomsReportsQuery();

	const recentReports =
		data?.sort((a, b) => b.redFlagCount - a.redFlagCount) ?? [];

	const customFromNowTodayAndYesterday = (date: number) => {
		const d = moment(date);

		if (d.isSame(moment(), 'day')) {
			return 'Hoy';
		} else if (d.isSame(moment().subtract(1, 'day'), 'day')) {
			return 'Ayer';
		} else {
			return d.fromNow();
		}
	};
	return (
		<StyledScreen
			css={{
				display: 'flex',
				flexDirection: 'row',
				padding: '30px',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			{isLoading ? (
				<StyledColumn
					css={{
						alignItems: 'center',
						justifyContent: 'center',
						width: '100%',
						height: '100%',
						display: 'inline-flex',
						flexDirection: 'column',
						gap: '16px',
					}}
				>
					<StyledCircularProgress size={50} />
				</StyledColumn>
			) : isError ? (
				<NotFoundMessage />
			) : (
				<StyledColumn
					css={{
						// maxWidth: '1300px',
						maxHeight: '700px',
						display: 'flex',
						flexDirection: 'column',
						gap: 16,
						width: '100%',
						padding: '24px',
						borderRadius: '20px',
						background: '#FFF',
						boxShadow: '0px 1px 25px 0px rgba(175, 126, 255, 0.10)',
					}}
				>
					<StyledBox
						css={{
							display: 'flex',
							paddingBottom: 8,
							justifyContent: 'space-between',
							alignItems: 'flex-end',
							width: '100%',
							borderBottom: `1px solid ${theme.gray50}`,
						}}
					>
						<StyledText
							variant="body2Bold"
							css={{
								color: theme.gray700,
								height: '17px',
							}}
						>
							Síntomas reportados
						</StyledText>
					</StyledBox>
					<StyledBox
						css={{
							display: 'flex',
							flex: 1,
							gap: '16px',
							overflowX: 'auto',
						}}
					>
						<StyledSymptomTable>
							<thead>
								<tr>
									<th></th>
									<th></th>
									{symptomsList?.map((symptom) => (
										<th key={symptom.id}>
											<StyledText
												variant="body1SemiBold"
												css={{
													color: theme.gray800,
													textAlign: 'center',
												}}
											>
												{symptom.name}
											</StyledText>
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{recentReports.length > 0 ? (
									recentReports.map((report) => (
										<tr
											key={report.patient.id}
											// onClick={() => handleRowClick(report)}
										>
											<td>
												<StyledText
													variant="body3Bold"
													css={{ color: theme.gray800 }}
												>
													{report.patient.name}{' '}
													{report.patient.surname}
												</StyledText>
											</td>
											<td>
												<StyledText
													variant="body3"
													css={{ color: theme.gray800 }}
												>
													{moment(
														report.latestReportDate,
													).isBetween(
														moment()
															.subtract(2, 'days')
															.startOf('day'),
														moment().endOf('day'),
														null,
														'[]',
													) ? (
														`${customFromNowTodayAndYesterday(
															report.latestReportDate,
														)}, ${moment(
															report.latestReportDate,
														).format('HH:mm')}`
													) : (
														<Moment format="DD/MM/YYYY, HH:mm">
															{report.latestReportDate}
														</Moment>
													)}
												</StyledText>
											</td>
											{symptomsList?.map((symptom) => {
												const matchingSymptom =
													report.symptoms.find(
														(s) => s.symptomId === symptom.id,
													);

												const backgroundColor = !matchingSymptom
													? theme.gray50
													: matchingSymptom.redFlag
													? theme.error400
													: theme.warning500;

												const fontColor = !matchingSymptom
													? theme.gray50
													: matchingSymptom.redFlag
													? theme.error700
													: theme.warning700;

												return (
													<td
														key={symptom.id}
														style={{
															verticalAlign: 'center',
															alignContent: 'center',
															// display: 'grid',
															// placeContent: 'center',
															// alignItems: 'center',
														}}
													>
														<StyledRow
															css={{ justifyContent: 'center' }}
														>
															<StyledBox
																css={{
																	backgroundColor:
																		backgroundColor,
																	color: fontColor,
																	height: '18px',
																	width: '18px',
																	borderRadius: '2px',
																	textAlign: 'center',
																	display: 'grid',
																	placeContent: 'center',
																}}
															>
																<StyledText variant="body3">
																	{
																		matchingSymptom?.intensityGrade
																	}
																</StyledText>
															</StyledBox>
														</StyledRow>
													</td>
												);
											})}
										</tr>
									))
								) : (
									<tr>
										<td colSpan={12}>
											<StyledBox
												css={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													height: '164px',
													width: '100%',
												}}
											>
												<StyledH6 css={{ color: theme.gray300 }}>
													Sin Datos
												</StyledH6>
											</StyledBox>
										</td>
									</tr>
								)}
							</tbody>
						</StyledSymptomTable>
					</StyledBox>
				</StyledColumn>
			)}
		</StyledScreen>
	);
};

export default RecentReportsScreen;
